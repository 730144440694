.home-atuacao-container {
  padding: 8vh 35px;
  text-align: center;
  max-width: 1000px;
  margin: auto;
}

.home-atuacao-container h1 {
  margin-bottom: 20px;
  color: #343434;
}

.home-atuacao-container .section-subtitle {
  color: #535353;
  font-size: 20px;
  font-family: "futura-pt-light";
  line-height: 1.6;
  max-width: 700px;
  margin: auto;
  margin-bottom: 35px;
}

.atuacao-flex {
  display: flex;
}

.atuacao-flex-row {
  flex: 1;
  flex: 0 0 50%;
  max-width: 50%;
}

.atuacao-flex-row div {
  height: 50%;
  padding: 10%;
  border-color: #eaeaea;
  border: 1px solid #eaeaea;
  position: relative;
}

.atuacao-flex-row div img {
  max-width: 54px;
  margin-bottom: 35px;
}

.atuacao-flex-row div h4 {
  color: #343434;
  font-family: "Playfair Display", "BlinkMacSystemFont", -apple-system, "Roboto",
    "Lucida Sans";
  font-size: 18px;
  font-weight: 400;
}


.atuacao-btn{
    border-radius: 0;
    text-transform: uppercase;
    width: 100%;
    position: absolute;
    top: 100%;
    width: calc(100% + 2px) !important;
    left: -1px;
    font-size: 12px;
    opacity: 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -moz-transform: rotateX(-90deg);
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    -moz-box-shadow: 0 5px 20px rgba(52,52,52,0.1);
    -webkit-box-shadow: 0 5px 20px rgba(52,52,52,0.1);
    box-shadow: 0 5px 20px rgba(52,52,52,0.1);
    transition: 0.3s linear;
    }

.ativo{
    -moz-transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    }


@media (max-width: 550px){
  .atuacao-flex{
    flex-direction: column;
    width: 100%;
  }

  .atuacao-flex-row{
    width: 100% !important;
    max-width: none;
  }

}