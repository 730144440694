/* playfair 22px weight 600, 1.3 height, m bottom 20px color #343434 futura, */
/* futura, 20px 535353, ma bottom 35px */

.edital-container,
.formulario-container{
    max-width: 730px;
    margin: auto;
    background-color: #fff;
}

.sub-title{
    line-height: 1.2 !important;
    margin-top: 10px;
}

.edital-container{
    padding-top: 90px;
}

.formulario-container{
    padding-top: 15px;
}

.edital-container h1{
    color: #343434;
}

.edital-container h2{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 1.3;
    color: #343434
}

.edital-container p{
    margin-bottom: 35px;
}
.edital-container p,
.formulario-container label{
    font-size: 20px;
    line-height: 1.6;
    color: #535353;
    text-rendering: optimizeLegibility;
}

.formulario-container form{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
}

.regular-label-container label{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.regular-label-container input,
.regular-label-container textarea{
    padding: 15px 20px;
    height: 38px;
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    border-radius: 0;
    background-color: #fff;
    border: 1px solid #5353537a;
}

.formulario-container button{
    max-width: 250px;
    width: 100%;
    margin: auto;
    margin-top: 10px;
}

.regular-label-container textarea{
    height: 150px;
}


.regular-label-container input:focus,
.regular-label-container textarea:focus{
    border: 1px solid #ccbd9b;
    border-radius: 0;
    outline: none;
}

.non-regular-label-container > div{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
}

.non-regular-label-container > div div,
.checkbox-container{
    display: flex;
    gap: 5px;
    align-items: center;
}

.non-regular-label-container input,
.checkbox-container input{
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.checkbox-container{
    margin: 10px 0;
    color: #535353;
}

@media (max-width: 450px){
    .edital-container{
     padding-top: 60px;   
    }

}