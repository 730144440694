
.notification{
    border-radius: 4px;
    padding: 14px 16px;
    position: relative;
    max-width: 450px;
    margin: 20px 0;
}

.notification .icon-container img{
  max-width: 30px;
  margin-right: 15px;
}

.notification.float{
    position: absolute;
    width: 80%;
   left: 40px;
   bottom: 40px;
}

.notification-content{
    display: flex;
    gap: 10px;
    align-items: center;
}


.notification .close-button{
    width: fit-content;
    position: absolute;
    top: 5px;
    right: 16px;
    font-size: 32px;
    margin: 0 !important;
    background-color: transparent !important;

}

.float-enter {
    opacity: 0;
  }
  
  .float-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .float-exit {
    opacity: 1;
  }
  
  .float-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  
  