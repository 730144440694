.App{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.body-wrapper{
  margin-top: 80px;
  min-height: 90vh;  
  background-color: #ffffff;
  padding-bottom: 80px;
}

.body-container{
  max-width: 1250px;
  padding: 0 20px;
  margin: auto;
  color: rgb(40, 40, 40);
}

input[type="radio"],
input[type="checkbox"]{
  cursor: pointer;
}

/* Fazer aqui um template, adiantando o setup abaixo: 

main-btn, secondary-btn e minor-btn;

h1 -> configurações de título: font, size, color

h2 -> configurações de subtítulo: font, size, color

*/
* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'futura-local';
  src: url('./assets/fonts/Futura/FuturaPTBook.otf') format('opentype');
}

a{
  text-decoration: none;
  color: #fff;
}

a, p{
font-family: 'futura-local';
color: #535353;
font-size: 20px;
}

.main-btn{
  font-family: 'futura-pt';
  font-size: 12px;
  display: block;
  height: 48px;
  background: #ee9982;
  background-color: #ccbd9b;
  color: #fff;
  padding: 0 20px;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  font-family: 'futura-pt';
  text-transform: uppercase;
}

.carrousel-btn{
  margin: auto;
  margin-top: 30px;
}

h1, h2, h3, h4, h5{
  font-family: 'Playfair Display', serif;
}

button{
  border: none;
  cursor: pointer;
}

.titulo-secoes{
  font-family: 'Prata', serif;
}

.App{
  font-family: 'Playfair Display', serif;

}
.body-container{
  max-width: 1150px;
  margin: auto;
}

